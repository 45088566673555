import React, { FC } from 'react'
import { GetServerSideProps } from 'next'
import { AdPlacementType, LabelProps, MetaDataProps, TagProps } from '@sport1/types/web'
import { getSport1Navigation } from '../api/cms/navigation'
import {
    getCompetitionsByTag,
    getLayoutByTag,
    getPaginatedLayout,
    getTagBySlug,
} from '../api/cms/content/tag/[id]'
import News from '@/pages/news'
import { PageProps } from '@/types/page'
import extendChannelPage from '@/utils/seo/extendChannelPage'
import useAutoPaging from '@/hooks/useAutoPaging'
import handleRejection, { PageNotFound } from '@/utils/errors/handleRejection'
import StandardLayout from '@/layouts/Standard'
import PageContentLayout from '@/layouts/Standard/PageContentLayout'
import { sportsLiveMap } from '@/components/Widget/Live'
import { addAdsToLayout, AdPlatform } from '@/helpers/adHelper'
import { setPageAdsActive } from '@/utils/ads/setAdStatusInContent'
import S1Error from '@/utils/errors/S1Error'
import { getOrderedCompetitions } from '@/pages/api/cms/content/tag/getOrderSportbetsCompetitions'

type Props = PageProps & {
    competitions: (TagProps | LabelProps)[]
    isNews?: boolean
    parentUrl?: string
}

const Index: FC<Props> = ({ layoutData, navigation, competitions, isNews, parentUrl }) => {
    const { pagedLayout } = useAutoPaging({
        tag: navigation.tag,
        isNews,
        layoutData,
    })
    if (isNews) {
        return <News parentUrl={parentUrl} navigation={navigation} layoutData={pagedLayout} />
    }

    return (
        <StandardLayout navigation={navigation} competitions={competitions}>
            <PageContentLayout layoutData={pagedLayout} />
        </StandardLayout>
    )
}

export const getServerSideProps: GetServerSideProps<Props | PageNotFound> = async ctx => {
    const slugs = [...(ctx.query.slug as string[])]
    const isNews = slugs[slugs.length - 1] === 'news'
    if (isNews) {
        slugs.pop()
    }
    const slug = `/${slugs.join('/')}`
    let navigationData, tag

    try {
        ;[navigationData, tag] = await Promise.all([getSport1Navigation(), getTagBySlug(slug)])
    } catch (error) {
        return handleRejection(error, { isNews, slug })
    }

    if (isNews) {
        let layoutData
        let parentUrl
        try {
            const newsTag = await getTagBySlug('/news')
            if (!newsTag.navigationItems?.length) {
                return handleRejection(new S1Error(404, 'No url provided for slug /news'))
            }

            parentUrl = newsTag.navigationItems[0].url
            layoutData = await getPaginatedLayout(parentUrl, tag)
        } catch (error) {
            return handleRejection(error, { isNews, slug })
        }
        await extendChannelPage({ isNews, layoutData, tag })
        return {
            props: {
                navigation: {
                    ...navigationData,
                    tag,
                    breadCrumbs: { pageTitle: tag.title && `${tag.title} News` },
                },
                layoutData,
                competitions: [],
                isNews,
                parentUrl,
            },
        }
    }

    let competitions: (TagProps | LabelProps)[] | undefined, result
    try {
        ;[competitions, result] = await Promise.all([
            getCompetitionsByTag(tag.contextId),
            getLayoutByTag(tag),
        ])
        competitions = getOrderedCompetitions(competitions, tag.id)

        if (sportsLiveMap.get(slug.substring(1))) {
            competitions?.push({
                contextId: '',
                contextType: 'TAG',
                title: 'Heute LIVE',
                slug,
                href: `/live${slug}`,
            })
        }
    } catch (error) {
        return handleRejection(error, { isNews, slug })
    }

    await extendChannelPage({ isNews, layoutData: result, tag })

    if (slug.includes('/sportwetten') || slug.startsWith('/casino')) {
        setPageAdsActive((result?.meta || {}) as MetaDataProps, false)
    }

    const tagNavigationItemsWihoutNews =
        tag?.navigationItems?.length && tag?.navigationItems?.length > 1
            ? [...tag.navigationItems]
            : []
    if (tagNavigationItemsWihoutNews.length > 1 && slug === '/darts-sport') {
        tagNavigationItemsWihoutNews.shift()
        tagNavigationItemsWihoutNews.forEach(item => {
            competitions?.push({
                contextId: '',
                contextType: 'TAG',
                title: item.title,
                href: item?.href as string | undefined,
            })
        })
    }

    addAdsToLayout({
        adInfos: [
            {
                placement: AdPlacementType.SKYSCRAPER,
            },
            {
                placement: AdPlacementType.INPAGE,
                platform: AdPlatform.WEB,
            },
        ],
        layout: result,
    })

    // Avoid App deeplinks for web only tag pages
    let deeplink = ''
    if (slug !== '/sportwetten') {
        deeplink = `sport1://tag/${tag.contextId}`
        ctx.res.setHeader('deeplink', deeplink)
    }

    return {
        props: {
            navigation: { ...navigationData, tag },
            layoutData: result,
            deeplink,
            competitions: competitions || [],
        },
    }
}

export default Index
